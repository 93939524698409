<template>
  <div>
    <DxTabPanel
      height="100%"
      :show-nav-buttons="true"
      :repaint-changes-only="true"
      :animation-enabled="true"
    >
      <DxItem title="ICMS">
        <template #default>
          <Icms />
        </template>
      </DxItem>
      <DxItem title="Totais Por CFOP">
        <template #default>
          <TotaisPorCFOP />
        </template>
      </DxItem>
      <DxItem title="Totais Por CST/CSOSN">
        <template #default>
          <TotaisPorCstCsosn />
        </template>
      </DxItem>
      <DxItem title="NCM x CST-ICMS">
        <template #default>
          <NcmxCstIcms />
        </template>
      </DxItem>
    </DxTabPanel>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>