import { DxTabPanel, DxItem } from 'devextreme-vue/ui/tab-panel';
import { defineComponent, defineAsyncComponent } from 'vue';

export default defineComponent({
  name: 'IcmsTabs',
  components: {
    DxTabPanel,
    DxItem,
    Icms: defineAsyncComponent(() => import('./icms/index.vue')),
    TotaisPorCFOP: defineAsyncComponent(() => import('./totais-por-cfop/index.vue')),
    TotaisPorCstCsosn: defineAsyncComponent(() => import('./totais-por-cst-csosn/index.vue')),
    NcmxCstIcms: defineAsyncComponent(() => import('./ncm-x-cst-icms/index.vue'))
  }
})